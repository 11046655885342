import './Footer.css';
import { Container, Row, Col } from 'react-bootstrap';

export default function Footer() {
    return (
        <footer className="Footer">
            <Row className="align-items-center">
                <Col>
                    <img className="Footer-logo" src="logo.png" />
                </Col>
                <Col>
                    <div className="Footer-copy">
                        Sim2Sim Technologies Ltd.
                        <div className="Footer-reg">
                            Registered office address 71-75 Shelton Street, London, England, WC2H 9JQ
                        </div>
                    </div>
                </Col>
            </Row>
        </footer>
    );
}