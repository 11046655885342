import './App.css';
import { Outlet } from "react-router-dom";
import Footer from './Footer.js';

function App() {
  return (
    <div className="App">
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;
